body {
    height: 100%;
    background: linear-gradient(0deg, white 50%, mediumpurple 50%);
	background-attachment: fixed;
}

h2 {
	padding-left: 1em;
	/* text-align: center; */
}

h3 {
	margin-top: 0;
	margin-bottom: 0;
}

h4 {
	margin-bottom: 0.5em;
}

ul {
	margin-top: 0.3em;
	font-size: calc(8px + 0.3em);
	padding-left: 3em
}

.profile-image {
	border-radius:50px;
	-webkit-box-shadow: 3px 4px 3px 0px #000000;
	box-shadow: 3px 4px 3px 0px #000000;
	margin-right: 1em;
	margin-bottom: 0.5em;
}

.badge {
	display:inline-block; 
	padding:5px;
}

.skill-header {
	font-weight: bold;
	font-size: 100%;
}

.skill {
	font-size: 80%;
}

.indent {
	padding-left: 3em;
}

.links {
	font-weight: normal;
	font-size: 75%;
}

.parent {
	display: inline-block;
	padding: 1rem;
}

.education {
	margin-top: 0;
	margin-bottom: 0;
}

.role {
	margin-top: 0;
	margin-bottom: 0;
}

.flex-parent {
	display: flex;
}

.flex-header {
	flex: 1;
	font-size:120%;
	font-weight: bold;
}
.flex-subheader{
	flex: 1;
	font-size:80%;
	font-style: italic;
}
.resume-section{
	display:block;
	background-color:lightblue;
	padding-right: 3em;
	padding: 0.5em;
	margin-bottom: 1em;
	-webkit-box-shadow: 3px 4px 3px 0px #000000; 
	box-shadow: 3px 4px 3px 0px #000000;
	border-radius:15px;
}
.side-by-side{
	display: flex;
}
.resume-half-section{
	width:48%;
	margin-right: 2%;
	padding-bottom: 1em;
}
.right {
	float:right;
	margin-right: 0;
}
.footer {
	background-color: black;
	text-decoration: none;
	font-size:40%;
	position: absolute;
	bottom:0;
	right:50%;
	-webkit-box-shadow: 3px 4px 3px 0px #000000; 
	box-shadow: 3px 4px 3px 0px #000000;
}
.footer-container {
	position: relative;
}

@media (max-width:800px)
{
	.right {
		float:none;
	}
	.resume-half-section {
		width: 100%;
		margin-right:0%;
		
		display:block;
		background-color:lightblue;
		padding: 0.5em;
		margin-bottom: 1em;
		-webkit-box-shadow: 3px 4px 3px 0px #000000; 
		box-shadow: 3px 4px 3px 0px #000000;
		border-radius:15px;
	}
	.side-by-side {
		display:block;
		padding-right: 1em;
	}
}